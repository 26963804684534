import React, { Component } from 'react'
import Link from '../utils/link'

class Footer extends Component {

	render() {

    const { linkedin, instagram } = this.props.data.wordpressAcfOptions.options

		return (

			<footer className='footer'>
				<div className='footer__inner'>
					<div className='footer__column'>
            <h5>Address</h5>
            <p>
              567 Spencer Street,<br />
              West Melbourne<br />
              VIC 3003<br />
              Australia
            </p>
          </div>
					<div className='footer__column'>
            <h5>Contact</h5>
            <p>
              <Link to='tel:03 9326 5692'>03 9326 5692</Link><br />
              <Link to='mailto:office@outlinesla.com.au'>office@outlinesla.com.au</Link>
            </p>
            <h5>Socials</h5>
            <p>
              <Link to={linkedin}>LinkedIn</Link><br />
              <Link to={instagram}>Instagram</Link>
            </p>
          </div>
					<div className='footer__column'>
            <h5>Copyright</h5>
            <p>
              © Outlines<br />
              All rights reserved
            </p>
          </div>
					<div className='footer__column'>
            <p>Outlines recognise and pay our deepest respect to the Wurundjeri, Bunurong and Wathaurong peoples of the Kulin Nation.</p>
					</div>
				</div>
			</footer>

		)

	}

}

export default Footer
