import React, { Component } from 'react'

import Link from '../utils/link'

class Header extends Component {

	isScrolling = false

	state = {
    scrolled: false,
    showHeader: true,
    menuActive: false,
    lastScrollTop: 0,
	}

	componentDidMount() {
    window.addEventListener('scroll', this.scrollDetect)
	}

	componentWillUnmount() {
	  window.removeEventListener('scroll', this.scrollDetect)
	}

	_toggleOffCanvas = () => {
    this.setState({ menuActive: !this.state.menuActive })
    document.querySelector('body').classList.toggle('menu-open')
    document.querySelector('.header__hamburger').classList.toggle('active')
  }

  _hideOffCanvas = () => {
    this.setState({ menuActive: false })
    document.querySelector('body').classList.remove('menu-open')
    document.querySelector('.header__hamburger').classList.remove('active')
  }

  onScroll = () => {
    let st = window.pageYOffset || window.scrollY

    this.setState({
      scrolled: window.scrollY > 50,
      lastScrollTop: st <= 0 ? 0 : st,
      showHeader: st <= this.state.lastScrollTop,
    })
  }

	scrollDetect = () => {
    window.clearTimeout( this.isScrolling )
    this.isScrolling = setTimeout(this.onScroll, 0);
	}

	render() {

		let { menuActive } = this.state

		let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active'
    }

		return (

			<>
				<header className={`header ${menuActive ? 'header--active' : ''}`}>
					<div className='header__inner'>

						<div className='header__logo'>
							<Link to='/'><img src={require('../assets/images/outlines_logo.svg')} alt='Outlines' /></Link>
						</div>


					</div>
				</header>

        <button className={`header__hamburger`} onClick={this._toggleOffCanvas}>
          <span className='lines'></span>
        </button>

        <div className='header__padding' />

				<div className={`${menuActive ? 'off-canvas active' : 'off-canvas'}`}>

					<nav className='off-canvas__nav'>
						<ul>
							<li><Link to='/about' {...props}>About</Link></li>
							<li><Link to='/services' {...props}>Services</Link></li>
							<li><Link to='/projects' {...props}>Projects</Link></li>
							<li><Link to='/contact' {...props}>Contact</Link></li>
						</ul>
					</nav>

				</div>
        { menuActive && <div className='off-canvas__touch' onClick={this._toggleOffCanvas} /> }

			</>

		)

	}

}

export default Header
